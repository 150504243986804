.console {
  min-width: 500px;
  width: 500px;
  height: 200px;
  position: absolute;
  overflow: hidden;
  border-radius: 10px;
  z-index: 1000;
  resize: both;
  color: #0ed800;
  transition: transform 0.5s cubic-bezier(0, 1.44, 0.32, 1.6); /* Плавное изменение наклона */
  perspective: 1000px;
  transform-origin: center; /* Центр вращения */
  user-select: none;
}
.console .topbar {
  width: 100%;
  height: 20px;
  background-color: rgb(0, 81, 255);
  cursor: grab;
  padding: 5px 0;
  text-align: center;
}
.console .console-content {
  width: 100%;
  height: calc(100% - 25px);
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: rgba(57, 57, 57, 0.6);
}

.console-text {
  user-select: text;
  font-size: 0.5rem;
  margin: 10px 0;
  font-weight: 700;
}
