@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
.press-start-2p-regular {
  font-family: 'Press Start 2P', system-ui;
  font-weight: 400;
  font-style: normal;
}
:root {
  --glow-color: hsl(186 100% 69%);

  --arrow-bg: rgba(255, 255, 255, 0.3);
  --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
  --option-bg: rgb(255, 0, 0);
  --select-bg: rgba(255, 255, 255, 0.2);
  --background-gradient: linear-gradient(178deg, #ffff33 10%, #3333ff);
  --gray: #34495e;
  --darkgray: #2c3e50;
}

.App {
  color: #00c8ff;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100dvh;
}
.App .flex-container {
  display: flex;
  height: calc(100dvh - 100px);
}
.drop-zone {
  padding: 10px;
  width: fit-content;
  min-width: 100px;
  display: flex;
  min-height: 500px;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.drop-zone .wallet {
  display: flex;
  width: 100%;
  padding: 1px;
  border: 1px solid #00c8ff;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  height: fit-content;
}

.wallet .address {
  width: 100%;
  font-size: 0.6rem;
  overflow: hidden;
}
.wallet .address {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}
.error {
  font-size: 0.6rem;
  color: red;
  width: 100%;
  text-align: start;
}
.error .private-key {
  font-size: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wallet .content {
  display: flex;
  flex-direction: column;
  width: 99%;
  position: relative;
  padding: 2px;
  border-radius: 10px;
  height: fit-content;
}
.close {
  font-size: 1rem;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  color: red;
  cursor: pointer;
}

select {
  position: relative;
  /* Reset Select */
  outline: 10px red;
  border: 0;
  box-shadow: none;
  /* Personalize */
  color: #00c8ff;
  width: 100%;
  flex: 1;
  padding: 0.5rem 0;
  background-color: transparent;

  cursor: pointer;
}
.error-message {
  font-size: 0.8rem;
  color: red;
}

/* Transition */
.select:hover::after {
  color: #f39c12;
}
.Table {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.loading {
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #c1c1c1;
  margin: 0 5px;
  animation: loading 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loading {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.controll-button {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  width: 20px;
  cursor: pointer;
  border: 1px solid #00c8ff;
  border-radius: 50%;
}
.controllers {
  display: flex;
  width: 100%;
  gap: 5px;
}
.tooltip {
  position: absolute;
  bottom: 100%; /* Располагаем выше элемента */
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px; /* Расстояние между элементом и tooltip */
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
  pointer-events: none; /* Чтобы tooltip не перехватывал события */
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.controll-button:hover .tooltip {
  opacity: 1;
}

.back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  border: 1px solid #00c8ff;
  border-radius: 20px;
  width: 100px;
  padding: 10px 0;
  font-weight: 900;
}
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 50%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b; /* $gray */
  outline: 0;
  font-size: 1.3rem;
  color: #fff; /* $white */
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b; /* $gray */
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #11998e; /* $primary */
  font-weight: 700;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #11998e, #38ef7d); /* $primary, $secondary */
  border-image-slice: 1;
}

/* Reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}
.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.35em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
  background: none;
  perspective: 2em;
  font-family: 'Raleway', sans-serif;
  font-size: 2em;
  font-weight: 900;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color), 0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color), 0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color), 0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn {
    font-size: 1em;
  }
}

.decoded-txdata {
  background-color: transparent;
  color: yellowgreen;
  text-wrap: nowrap;
}
