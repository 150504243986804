.rpc-manager {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  gap: 3rem;
}
.wrapper {
  width: 100px;
  aspect-ratio: 1/1;
  transition: all 0.3s ease-in-out;
}
.rpc-cell {
  width: 70px;
  height: 70px;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(30deg) rotateY(-40deg);
  animation: boldier 6s infinite;
  color: black;
}
.wrapper:hover .rpc-cell {
  animation: none;
  transform: rotateX(30deg) rotateY(-40deg);
}
.wrapper:hover .rpc-cell .front {
  transform: translateZ(45px);
}
.wrapper:hover .rpc-cell .right {
  transform: rotateY(90deg) translateZ(45px);
}
.wrapper:hover .rpc-cell .top {
  transform: rotateX(-90deg) translateZ(45px);
}

.wrapper:active {
  transition: all 0.1s ease-in-out;
}
.wrapper.selected {
  z-index: 100;
  scale: 1.1;
  opacity: 1;
}
.wrapper:not(.selected) {
  scale: 0.9;
  opacity: 0.5;
}
.rpc-manager:hover .wrapper.selected {
  z-index: 1;
  scale: 1;
}
.rpc-manager:hover .wrapper:not(.selected) {
  scale: 1;
  transform: translateY(0);
  opacity: 1;
}
.rpc-cell .side {
  position: absolute;
  width: 70px;
  transition: all 0.3s ease-in-out;

  height: 70px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-weight: 100;
  font-size: 14px !important;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5); /* Добавляем тень */
}

/* Добавляем тень к каждой стороне */
.rpc-cell .front {
  transform: translateZ(35px); /* Уменьшаем Z-позицию на половину от 70px */
  background-color: #00ff00; /* зеленая передняя сторона */
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5); /* Тень для передней стороны */
}
.rpc-cell .front span {
  transform: rotate(45deg); /* Уменьшаем Z-позицию на половину от 70px */
  font-size: 10px;
}

.rpc-cell .right {
  transform: rotateY(90deg) translateZ(35px); /* Уменьшаем Z-позицию на половину от 70px */
  background-color: #ff0000; /* красная правая сторона */
  box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.5); /* Тень для правой стороны */
}

.rpc-cell .top {
  transform: rotateX(-90deg) translateZ(35px); /* Уменьшаем Z-позицию на половину от 70px */
  background-color: #675fff; /* синяя верхняя сторона */
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.5);
}

@keyframes boldier {
  0% {
    transform: rotateX(20deg) rotateY(-30deg);
  }
  25% {
    transform: rotateX(30deg) rotateY(-60deg);
  }
  50% {
    transform: rotateX(60deg) rotateY(-30deg);
  }
  100% {
    transform: rotateX(20deg) rotateY(-30deg);
  }
}
